export interface IServiceWorkerImportMap {
    [key: string]: IServiceWorkerImportMapEntry;
}

export interface IServiceWorkerImportMapSiteEntry {
    type: 'site';
    version: string;
    importUrl: string;
    fingerprint: string;
    name: string;
}

export interface IServiceWorkerImportMapCdnEntry {
    type: 'cdn';
    version: string;
    importUrl: string;
    moduleName?: string;
    moduleId: string;
}

export type IServiceWorkerImportMapEntry = IServiceWorkerImportMapSiteEntry | IServiceWorkerImportMapCdnEntry;
